<template>
  <v-container fluid class="d-flex fill-height flex-column justify-center align-center">
    <p class="title-po">404</p>
    <p class="subtitle-po">{{ $t('app.404.title') }}</p>
    <v-btn class="subtitle" text @click="$router.push({name: 'Home'})">{{ $t('app.404.back') }}</v-btn>
  </v-container>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "NotFoundConnected",
  ...Vuex.mapState(["preferences"]),
  currentLang() {
    return this.preferences.lang
  },
  beforeMount() {
    this.$i18n.locale = this.currentLang
    this.$vuetify.lang.current = this.currentLang

  }
}
</script>

<style scoped>

</style>